@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.siteMapWrapper{
  .container{
    width: 1030px;
    @include breakpoint-down(sm) {
      width: 100%;
    }
    .staticInfo {
      width: 100%;
      height: 100%;
      margin-top: 100px;
      h1 {
        font-family: 'Barlow', sans-serif;
        margin-bottom: 1rem;
        font-size: 36px;
        line-height: 50px;
        color: #0E0F0F;
        font-weight: 700;
        @include breakpoint-down(sm) {
          font-size: 28px;
          line-height: 40px;
          text-align: center;
        }
      }
      .digitalIntro{
        padding-bottom: 30px;
        margin-bottom: 40px;
        border-bottom: 1px solid #e1e1e1;
        .digitalImage{
          width: 100%;
          height: 230px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
       
      }
      .adventuresTiles{
        width: 100%;
        margin-bottom: 50px;
        .tiles{
          margin-bottom: 30px;
          .image{
            position: relative;
            float: left;
            z-index: 1;
            img{
              width: 100%;
            }
            &::after{
              content: '';
              background: url('../../Assets/Images/imageBlur.png') no-repeat;
              background-position: bottom;
              height: 180px;
              width: 100%;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              background-size: cover;
            }
          }
          .info{
            width: 100%;
            height: 280px;
            background-color: #EFFAFF;
            padding: 0 28px 28px 28px;
            float: left;
            position: relative;
            top: -1.5rem;
            z-index: 9;
            h4{
              font-size: 20px;
              line-height: 23px;
              font-weight: 600;
              color: #0E0F0F;
            }
            p{
              font-size: 18px;
              line-height: 25px;
              color: #0E0F0F;
            }
          }
        }
      }
      .staticBottomInfo{
        margin: 66px 0 110px 0;
        @include breakpoint-down(sm) {
          margin: 40px 0 40px 0;
        }
        h4{
          margin-bottom: 16px;
          font-size: 18px;
          line-height: 23px;
          color: #0E0F0F;
          font-family: 'Barlow', sans-serif;
        }
        p{
          font-size: 18px;
          line-height: 25px;
          color: #0E0F0F;
        }
      }
    }
  }
}