@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.siteMapWrapper{
  .container{
    width: 1030px;
    @include breakpoint-down(sm) {
      width: 100%;
    }
    .staticInfo {
      width: 100%;
      height: 100%;
      margin-top: 100px;
      h1 {
        font-family: 'Barlow', sans-serif;
        margin-bottom: 1rem;
        font-size: 36px;
        line-height: 50px;
        color: #0E0F0F;
        font-weight: 700;
        @include breakpoint-down(sm) {
          font-size: 28px;
          line-height: 40px;
          text-align: center;
        }
      }
      .bannerImg{
        margin: 30px 0 44px 0;
        img{
          width: 100%;
        }
      }
      p{
        font-size: 18px;
        line-height: 25px;
        color: #0E0F0F;
      }
      .staticInfoList{
        margin: 5px 0 0 0;
        .infoList{
          display: flex;
          justify-content: space-between;
          margin-top: 45px;
          @include breakpoint-down(sm) {
            flex-direction: column;
            margin-top: 30px;
          }
          .image{
            width: 25%;
            margin-right: 34px;
            @include breakpoint-down(sm) {
              width: 100%;
            }
            img{
              width: 100%;
              @include breakpoint-down(sm) {
                margin-bottom: 20px;
              }
            }
          }
          .info{
            width: 75%;
            @include breakpoint-down(sm) {
              width: 100%;
            }
            h4{
              margin-bottom: 18px;
              font-size: 18px;
              line-height: 25px;
              color: #0E0F0F;
              font-family: 'Barlow', sans-serif;
            }
            p{
              font-size: 18px;
              line-height: 25px;
              color: #0E0F0F;
            }
          }
        }
      }
      .staticBottomInfo{
        margin: 66px 0 110px 0;
        @include breakpoint-down(sm) {
          margin: 40px 0 40px 0;
        }
        h4{
          margin-bottom: 16px;
          font-size: 18px;
          line-height: 23px;
          color: #0E0F0F;
          font-family: 'Barlow', sans-serif;
        }
        p{
          font-size: 18px;
          line-height: 25px;
          color: #0E0F0F;
        }
      }
      .blockInfo{
        background-color: #FF7722;
        width: 100%;
        padding: 37px;
        p{
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 0;
          color: #ffffff;
        }
      }
    }
  }
}