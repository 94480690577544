@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.staticBlogsInfo{
  .BlogDetailWrapper{
    .BlogDetailImg{
      img{
        width: 100%;
        height: 600px;
        object-fit: cover;
        object-position: center;
        @include breakpoint-down(lg) {
          height: auto;
        }
      }
      .blogTagsInfo{
        margin-top: 8px;
        font-size: 16px;
        line-height: 25px;
        color: $black-color;
        span{
          font-weight: 600;
        }
      }
    //   p{
    //     margin-top: 16px;
    //     font-size: 18px;
    //     line-height: 25px;
    //     color: $black-color;
    //   }
     }
    h3{
      font-size: 32px;
      line-height: 40px;
      margin: 26px 0 25px 0;
      color: $black-color;
      font-family: 'Barlow', sans-serif;
    }
    p{
      font-size: 18px;
      line-height: 25px;
      color: $black-color;
    }
    .BlogDetailInfo{
      width: 100%;
      h4{
        margin-bottom: 0;
      }
    }
  }
  .blogToMove{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 44px;
    &__previous{
      a{
        background: url('../../Assets/Images/previousArrow.svg')no-repeat;
        height: 58px;
        width: 58px;
        display: flex;
        align-items: center;
        text-decoration: none;
        span{
          font-size: 28px;
          line-height: 28px;
          position: relative;
          left: 75px;
          color: $black-color;
        }
      }
    }
    &__next{
      a{
        background: url('../../Assets/Images/nextArrow.svg')no-repeat;
        height: 58px;
        width: 58px;
        display: flex;
        align-items: center;
        text-decoration: none;
        span{
          font-size: 28px;
          line-height: 28px;
          position: relative;
          right: 60px;
          color: $black-color;
        }
      }
    }
  }
}