@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;

.ridersSayingWrapper{
  width: 100%;
  display: flex;
  background: $gradient-secondary url('../../Assets/Images/pathThree.png') no-repeat;
  background-position: 0% 120%;
  background-size: 28%;
  position: relative;
  padding-bottom: 75px;
  @include breakpoint-down(sm) {
    padding: 30px 15px;
    background-image: none;
  }
  .header{
    width: 100%;
    padding: 40px 0 0 0;
    text-align: center;
    background: url('../../Assets/Images/quote.png') no-repeat;
    background-position: center;
    background-size: 115px;
    @include breakpoint-down(sm) {
      padding: 0;
      background-size: 85px;
    }
    h2{
      font-size: 40px;
      line-height: 45px;
      color: $black-color;
      position: relative;
      top: 25px;
      @include breakpoint-down(sm) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    p{
      font-size: 18px;
      line-height: 33px;
      color: $gray-color;
      position: relative;
      top: 25px;
      @include breakpoint-down(sm) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .carouselRiderSay{
    width: 100%;
    text-align: center;
    .carousalMask{
      width: 814px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      @include breakpoint-down(sm) {
        width: 100%;
      }
      h4{
        font-size: 34px;
        line-height: 51px;
        color: $black-color;
        font-weight: 400;
        margin-top: 40px;
        @include breakpoint-down(sm) {
          font-size: 18px;
          line-height: 30px;
        }
      }
      img{
        width: 148px;
        margin: 46px 0 16px 0;
      }
      h5{
        font-size: 26px;
        line-height: 33px;
        font-weight: 600;
        color: $black-color;
        margin-bottom: 0;
        @include breakpoint-down(sm) {
          font-size: 20px;
          line-height: 20px;
        }
      }
      p{
        font-size: 18px;
        line-height: 33px;
        color: $gray-color;
        margin: 8px 0 15px 0;
        @include breakpoint-down(sm) {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
    .owl-nav{
      position: absolute;
      width: 100%;
      top: 50%;
      .owl-prev{
        background: url('../../Assets/Images/sayingArrowPrev.png') no-repeat;
        width: 65px;
        height: 65px;
        float: left;
        font-size: 0;
        @include breakpoint-down(sm) {
          background-size: 40px;
          width: 40px;
          height: 40px;
        }
      }
      .owl-next{
        background: url('../../Assets/Images/sayingArrowNext.png') no-repeat;
        width: 65px;
        height: 65px;
        float: right;
        font-size: 0;
        @include breakpoint-down(sm) {
          background-size: 40px;
          width: 40px;
          height: 40px;
        }
      }
    }
    .owl-dots{
      .owl-dot{
        background-color: $border-color;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin: 5px;
        &.active{
          background: $blue-color;
        }
        span{
          display: none;
        }
      }
    }
  }
}