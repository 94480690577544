@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;

.customNavmenu{
  .navbar{
    width: 100%;
    //transition: all 0.5s ease;
    &.scrolled{
      position: fixed;
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
      padding-bottom: 10px;
      transition: all 0.4s ease;
      background-color: $white-color;
      // animation: slideDown 0.35s ease-out;
      .navMenuInner{
        .outer-menu{
          .hamburger{
            div{
              background: $black-color !important;
              &::before{
                background: $black-color !important;
              }
              &::after{
                background: $black-color !important;
              }
            }
          }
          .menu {
            height: 100vh !important;
            width: 100% !important;
            top: 0 !important;
            bottom: 0 !important;
            position: absolute !important;
          }
        } 
        .socialLinks{
          svg{
            fill: $black-color !important;
            color: $black-color !important;
          }
        }
      }
    }
    .navMenuInner{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .navMenu{
        .flex-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        * {
          box-sizing: border-box;
          font-weight: 300;
        }
        a,
        a:visited,
        a:hover,
        a:active {
          color: inherit;
          text-decoration: none;
        }
        .outer-menu .checkbox-toggle {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          cursor: pointer;
          width: 60px;
          height: 60px;
          opacity: 0;
        }
        .outer-menu .checkbox-toggle:checked + .hamburger > div {
          transform: rotate(135deg);
          height: 2px;
          width: 100%;
        }
        .outer-menu .checkbox-toggle:checked + .hamburger > div:before,
        .outer-menu .checkbox-toggle:checked + .hamburger > div:after {
          top: 0;
          width: 100%;
          transform: rotate(90deg);
        }
        .outer-menu .checkbox-toggle:checked + .hamburger > div:after {
          opacity: 0;
        }
        .outer-menu .checkbox-toggle:checked ~ .menu {
          pointer-events: auto;
          visibility: visible;
        }
        .outer-menu .checkbox-toggle:checked ~ .menu > div {
          transform: scale(1);
          transition-duration: 0.75s;
        }
        .outer-menu .checkbox-toggle:checked ~ .menu > div > div {
          opacity: 1;
          transition: opacity 0.4s ease 0.4s;
        }
        // .outer-menu .checkbox-toggle:hover + .hamburger {
        //   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
        // }
        .outer-menu .checkbox-toggle:checked:hover + .hamburger > div {
          transform: rotate(225deg);
        }
        .outer-menu .hamburger {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 60px;
          height: 60px;
          padding: 0.5em 1em;
          //background: rgba(33, 150, 243, 0.75);
          border-radius: 0 0.12em 0.12em 0;
          cursor: pointer;
          transition: box-shadow 0.4s ease;
          -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .outer-menu .hamburger > div {
          position: relative;
          flex: none;
          width: 100%;
          height: 1.6px;
          background: $white-color;
          transition: all 0.4s ease;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .outer-menu .hamburger > div:before,
        .outer-menu .hamburger > div:after {
          content: "";
          position: absolute;
          z-index: 1;
          top: -8px;
          left: 0;
          width: 55%;
          height: 2px;
          // background: inherit;
          background-color: $white-color;
          transition: all 0.4s ease;
        }
        .outer-menu .hamburger > div:after {
          top: 9px;
          left: 13px;
          background-color: $white-color;
        }
        .outer-menu .menu {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          visibility: hidden;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
          outline: 1px solid transparent;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .outer-menu .menu > div {
          width: 200vw;
          height: 200vw;
          color: #fefefe;
          background-color: $base-color;;
          border-radius: 50%;
          transition: all 0.4s ease;
          flex: none;
          transform: scale(0);
          -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          @include breakpoint-down(sm) {
            height: 250vw;
          }
        }
        .outer-menu .menu > div > div {
          text-align: center;
          max-width: 90vw;
          max-height: 100vh;
          opacity: 0;
          transition: opacity 0.4s ease;
          overflow-y: auto;
          flex: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .outer-menu .menu > div > div > ul {
          list-style: none;
          padding: 0 1em;
          margin: 0;
          display: block;
          max-height: 100vh;
        }
        .outer-menu .menu > div > div > ul > li {
          padding: 0;
          margin: 0;
          font-size: 50px;
          font-size: 800;
          display: block;
        }
        .outer-menu .menu > div > div > ul > li > a {
          position: relative;
          display: inline;
          cursor: pointer;
          transition: color 0.4s ease;
        }
        .outer-menu .menu > div > div > ul > li > a:hover {
          color: #e5e5e5;
        }
        .outer-menu .menu > div > div > ul > li > a:hover:after {
          width: 100%;
        }
        .outer-menu .menu > div > div > ul > li > a:after {
          content: "";
          position: absolute;
          z-index: 1;
          bottom: -0.15em;
          left: 0;
          width: 0;
          height: 2px;
          background: #e5e5e5;
          transition: width 0.4s ease;
        }
        
      }
      .navLogo{
        margin-left: 15rem;
        @include breakpoint-down(sm) {
          margin-left: 5rem;
        }
        svg{
          width: 110px;
          height: auto;
        }
      }
      .socialLinks{
        ul{
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
          li{
            margin-left: 24px;
            @include breakpoint-down(sm) {
              margin-left: 12px;
            }
            a{
              svg{
                width: 20px;
                fill: $white-color;
              }
              &:hover{
                svg{
                  fill: #FF7824;
                  transition: all 0.5s ease;
                }
              }
            }
          }
        }
      }
    }
    @keyframes slideDown {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }
  }
}

.navbar.scrolled{
  .navMenuInner{
      .socialLinks{
        ul{
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
          li{
            margin-left: 24px;
            @include breakpoint-down(sm) {
              margin-left: 12px;
            }
            a{
              svg{
                width: 20px;
                fill: $white-color;
              }
              &:hover{
                svg{
                  fill: #FF7824 !important;
                  transition: all 0.5s ease;
                }
              }
            }
          }
        }
      }
  }
}  



//.customNavmenu{
  // .navbar{
  //   width: 100%;
  //   background: rgb(153, 153, 153, 0.55);
    // filter: blur(38px);
    // -webkit-filter: blur(38px);
    //filter: brightness(50%);
  // }
  // .socialLinks{
  //   margin: 0;
  //   padding: 0;
  //   list-style: none;
  // }

  // Animation to fade in the dropdown menus
//@keyframes fadeIn {
//   from {opacity: 0;}
//   to {opacity: 1;}
// }

// // Assigning the above animation to all dropdown menus
// .dropdown-menu{
//   &.show{
//       -webkit-animation: fadeIn 0.3s alternate; /* Safari 4.0 - 8.0 */
//        animation: fadeIn 0.3s alternate;
//   }
// }

// // Mega Menu adjustments
// .nav-item.dropdown.dropdown-mega{
//   position: static;
//   .dropdown-menu{
//       width: 90%;
//       top: auto;
//       left: 5%;
//   }
// }

// // Complete styles for main navbar toggler and hamburger menu
// .navbar-toggler{
//   border: none;
//   padding: 0;
//   outline: none;
//   &:focus{
//       box-shadow: none;
//   }
//   .hamburger-toggle{
//       position: relative;
//       display: inline-block;
//       width: 50px;
//       height: 50px;
//       z-index: 11;
//       float: right;
//       .hamburger {
//           position: absolute;
//           transform: translate(-50%, -50%) rotate(0deg);
//           left: 50%;
//           top: 50%;
//           width: 50%;
//           height: 50%;
//           pointer-events: none;
//           span {
//               width: 100%;
//               height: 4px;
//               position: absolute;
//               background: #333;
//               border-radius: 2px;
//               z-index: 1;
//               transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0), background 0.2s cubic-bezier(0.77,0.2,0.05,1.0), all 0.2s ease-in-out;
//               left: 0px;
//               &:first-child{
//                   top: 10%;
//                   transform-origin: 50% 50%;
//                   transform: translate(0% -50%) !important;
//               }
//               &:nth-child(2){
//                   top: 50%;
//                   transform: translate(0,-50%);
//               }
//               &:last-child{
//                   left: 0px;
//                   top: auto;
//                   bottom: 10%;
//                   transform-origin: 50% 50%;
//               }
//           }
//           &.active{
//               span {
//                   position: absolute;
//                   margin: 0;
//                   &:first-child{
//                       top: 45%;
//                       transform: rotate(45deg);
//                   }
//                   &:nth-child(2){
//                       left: 50%;
//                       width: 0px;
//                   }
//                   &:last-child{
//                       top: 45%;
//                       transform: rotate(-45deg);
//                   }
//               }
//           }
//       }
//   }
// }


// .icons{
// display: inline-flex;
// margin-left: auto;
// a{
//   transition: all 0.2s ease-in-out;
//   padding: 0.2rem 0.4rem;
//   color: #ccc !important;
//   text-decoration: none;
//   &:hover{
//     color: white;
//     text-shadow: 0 0 30px white;
//   }
// }
// }
// }