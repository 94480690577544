@use "./ThemeConfig/Variables.scss" as *;

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Rajdhani:wght@400;500;600;700&display=swap');

//font-family: 'Barlow', sans-serif;
//font-family: 'Rajdhani', sans-serif;

body{
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  width: 100%;
  overflow-x: hidden;
}

 /* width */
::-webkit-scrollbar {
  width: 8px;
  border-right: 8px solid #c1c1c1;
  background-color: #c1c1c1;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF7722;
  border-radius: 5px;
  width: 8px;
  // border-right: 10px solid #ffffff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FF7722;
  width: 8px;
  // border-right: 10px solid #ffffff;
}

.cmsPagesWrapper{
  .staticInfo{
    width: 100%;
    height: 100%;
    margin-top: 100px;
    h2{
      font-family: 'Barlow', sans-serif;
      margin-bottom: 1rem;
      font-size: 40px;
      line-height: 45px;
    }
    h4{
      font-size: 22px;
      line-height: 26px;
    }
    p{
      font-size: 18px;
      line-height: 25px;
      width: 98%;
      margin: 0 0 20px 0; 
    }
    ul{
      list-style-type: disc;
       margin: 0 0 20px 0;
       padding: 0 0 0 20px; 
       li{
        font-size: 18px;
        line-height: 25px;
        opacity: 0.8;
        width: 98%;
        position: relative;
        padding: 0;
      }
    }
    .accordion{
      margin-bottom: 100px;
      .accordion-item{
        border: medium none;
        // border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        .accordion-header{
          font-size: 20px;
          line-height: 23px;
          color: $black-color;
          font-family: 'Barlow', sans-serif;
          font-weight: 600;
          margin-bottom: 0;
          .accordion-button{
            background: transparent;
            box-shadow: none;
          }
          .accordion-button:not(.collapsed){
            color: $black-color;
          }
          .accordion-button[aria-expanded="true"]{
            &::after{
              content: '';
              background: url('./Assets/Images/minus-cirlce.svg') no-repeat;
              height: 25px;
              width: 25px;
            }
            &:hover::after{
              background: url('./Assets/Images/minus-cirlce-hover.svg') no-repeat;
              transition: all 0.5s ease;
            }
            &:focus{
              border:  medium noen !important;
              border-color: transparent !important;
              box-shadow: none !important;
            }
          }
          .accordion-button[aria-expanded="false"]{
            &::after{
              content: '';
              background: url('./Assets/Images/add-circle.svg') no-repeat;
              height: 25px;
              width: 25px;
            }
            &:hover::after{
              background: url('./Assets/Images/add-circle-hover.svg') no-repeat;
              transition: all 0.5s ease;
            }
            &:focus{
              border:  medium noen !important;
              border-color: transparent !important;
              box-shadow: none !important;
            }
          }
          // .collapsed{
            
          // }
        }
        .accordion-collapse{
          .accordion-body{
            padding: 0 2rem 1.5rem 2rem; 
            font-size: 18px;
            line-height: 25px;
            color: $black-color;
          }
        }
      }
    }
  }
  .customNavmenu{
    .navbar{
      .navMenuInner{
        .navMenu{
          a{
            svg{
              line{
                stroke: $black-color;
              }
              
            }
          }
        }
        .socialLinks{
          li{
            a{
              svg{
                fill: $black-color;
              }
            }
          }
        }
      }
    }
  }
  .noInfoWrapper{
    height: calc(100vh - 30vh);
    margin-top: 6rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2{
      margin-top: 1rem;
    }
  }
  
}

.cmsPagesWrapper{
  .outer-menu .hamburger > div{
    background-color: $black-color !important;
  }
  .outer-menu .hamburger > div:before,
  .outer-menu .hamburger > div:after {
    background-color: $black-color !important;
  }
}

.siteMapWrapper{
  .customNavmenu{
    .navbar{
      .navMenuInner{
        .navMenu{
          a{
            svg{
              line{
                stroke: $black-color;
              }
              
            }
          }
        }
        .socialLinks{
          li{
            a{
              svg{
                fill: $black-color;
              }
            }
          }
        }
      }
    }
  }
  .outer-menu .hamburger > div{
    background-color: $black-color !important;
  }
  .outer-menu .hamburger > div:before,
  .outer-menu .hamburger > div:after {
    background-color: $black-color !important;
  }
}
