@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;

.staticBlogsInfo{
    width: 100%;
    height: 100%;
    margin: 100px 0 108px 0;
    h2{
      font-size: 40px;
      line-height: 45px;
      font-family: 'Barlow', sans-serif;
      font-weight: 700;
      margin-bottom: 35px;
      text-align: center;
    }
    .blogsListLink{
      text-decoration: none;
      .blogsList{
        width: 100%;
        margin-bottom: 40px;
        .bannerImg{
          position: relative;
          overflow: hidden;
          .image {
            opacity: 1;
            display: block;
            width: 100%;
            height: 252px;
            backface-visibility: hidden;
            object-fit: cover;
            object-position: center;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .5s ease-in-out;
          }
          .middle {
            transition: .5s ease;
            opacity: 0;
            background: $base-primary-rgb;
            background: $base-primary-rgba; /* Black see-through */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
            position: absolute;
            z-index: 9;
          }
          // &::after{
          //   content: '';
          //   float: left;
          //   position: absolute;
          //   bottom: 0;
          //   right: 0;
          //   border-bottom: 38px solid $blue-color;
          //   border-left: 38px solid transparent;
          // }
          &:hover .image{
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
          &:hover .middle {
            opacity: 1;
            height: 100%;
            width: 100%;
          }
        }
        .blogsListInfo{
          position: relative;
          height: 300px;
          padding: 24px 24px 33px 24px;
          border: 1px solid #E3E4E5;
          @include breakpoint-down(md) {
            height: 390px;
          }
          @include breakpoint-down(sm) {
            height: auto;
          }
          h2{
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;
            font-family: 'Barlow', sans-serif;
            font-weight: 600;
            color: $black-color;
            text-align: left;
            text-transform: uppercase;
            display: -webkit-box; 
            -webkit-box-orient: vertical; 
            -webkit-line-clamp: 3; 
            overflow: hidden; 
            text-overflow: ellipsis; 
          }
          .blogShortInfo{
            p{
              font-size: 18px;
              line-height: 25px;
              margin-bottom: 10px;
              color: $black-color;
            }
          }
          a{
            position: absolute;
            bottom: 30px;
            font-size: 16px;
            line-height: 28px;
            color: $black-color;
            text-decoration: none;
            font-weight: 600;
            @include breakpoint-down(lg) {
              bottom: 5px;
            }
            &:hover{
              color: #FF7722;
              transition: all 0.5s ease;
            }
          }
        }
      }
    }
  .paginationWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .pagination{
      .page-link{
        padding: 0;
        color: $black-color;
        height: 39px;
        width: 39px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 6px;
        font-size: 16px;
        line-height: 28px;
        color: #676767;
        font-weight: 600;
        &:focus{
          background-color: #FF7722;
          color: #ffffff;
          box-shadow: unset;
        }
      }
    }
    
  }
}
