@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;

.linknlistss {
  display: inline-block;
  @include breakpoint-down(sm) {
    width: 50%;
  }
}
.linknlistss ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.linknlistss ul li {
  margin-bottom: 10px;
}
.linknlistss ul li a {
  color: #0E0F0F;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.5s ease;
}
.linknlistss ul li a:hover {
  text-decoration: none;
  transition: all 0.5s ease;
  color:#FF7722;
}

.footerwrapper__copyright .infoText {
  color: #676767;
  font-size: 16px;
  line-height: 33px;
  width: 100%;
  text-align: center;
}

.footerwrapper{
  width: 100%;
  background: $white-color;
  padding: 60px 0 25px 0;
  border-top: 1px solid $border-color;
  @include breakpoint-down(sm) {
    padding: 25px 0;
  }
  &__info{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    @include breakpoint-down(sm) {
      display: inherit;
      text-align: center;
    }
    .logoImage{
      &__img{
        .logo{
          svg{
            width: 150px;
            padding-right: 15px;
          }
        }
        .india{
          img{
            padding-left: 15px;
            border-left: 1px solid #ccc;
            width: 300px;
            @include breakpoint-down(sm) {
              margin: 20px 0;
              border:none;
              padding: 0;
            }
          }
        }
      }
      svg{
        @include breakpoint-down(sm) {
          width: 100px;
        }
      }

      p{
        width: 650px;
        font-size: 16px;
        line-height: 27px;
        color: $gray-color;
        margin: 24px 0 30px 0;
        font-weight: 500;
        @include breakpoint-down(sm) {
          width: 100%;
          font-size: 16px;
          line-height: 25px;
          margin: 0 0 25px 0;
        }
      }
    }
    .appBtns{
      float: right;
      display: flex;
      flex-direction: column;
      @include breakpoint-down(sm) {
        float: none;
        display: inherit;
        margin-bottom: 20px;
      }
      h3{
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 33px;
        color: $black-color;
        font-family: 'Barlow', sans-serif;
      }
      img{
        width: 135px;
        margin-bottom: 12px;
        @include breakpoint-down(sm) {
            margin: 0px 5px;
        }
      }
    }
  }
  &__copyright{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    @include breakpoint-down(sm) {
      display: flex;
    }
    @include breakpoint-down(xs) {
      display: inherit;
      padding-top: 20px;
      text-align: center;
    }
    .infoText{
      font-size: 16px;
      line-height: 33px;
      color: $gray-color;
      p{
        font-size: 12px;
        @include breakpoint-down(sm) {
          margin-bottom: 0;
        }
      }
    }
    .footerMenu{
      @include breakpoint-down(sm) {
        text-align: center;
      }
      ul{
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        @include breakpoint-down(sm) {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        li{
          margin-left: 32px;
          @include breakpoint-down(sm) {
            margin-left: 15px;
          }
          a{
            font-size: 16px;
            line-height: 33px;
            color: $gray-color;
            text-decoration: none;
          }
        }
      }
    }
  }
}