.cmsPagesWrapper{
  .staticInfo{
    .siteMapTree{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      background: #f9f9f9;
      margin-bottom: 3rem;
      padding: 2rem;
      border-radius: 10px;
      border: 1px solid #f1f1f1;
      ul{
        margin-bottom: 0;
        li{
          color: #FF7722;
          width: 100%;
          a{
            color: #FF7722;
            text-decoration: none;
            font-weight: 500;
            &:hover{
              border-bottom: 1px solid #FF7722;
              transition: all 0.5s ease-out;
            }
          }
          &:last-child{
            color: #4baddf;
            font-weight: 700;
            ul{
              list-style-type: square;
              margin-bottom: 0;
              li{
                color: #4baddf;
                width: 100%;
                a{
                  color: #000;
                  &:hover{
                    border-bottom: 1px solid #4baddf;
                    transition: all 0.5s ease-out;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



