@use './variables.scss' as *;

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}
  
@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}