@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;

// .bannerWrapper{
//   width: 100%;
//   background:#e1e1e1 url('../../Assets/Images/banner.jpg') no-repeat;
//   height: calc(100vh - 0px);
//   background-size: cover;
//   background-position: top center;
//   position: relative;
//   .carouselBanner{
//     background-color: $base-color;
//     position: absolute;
//     width: 330px;
//     height: 355px;
//     left: 190px;
//     bottom: 0;
//     padding: 50px 0px;
  
//     @include breakpoint-down(sm) {
//      left: 130px;
//     }
//     @include breakpoint-down(xs) {
//       width: 88%;
//       left: 20px;
//       height: auto;
//     }
//     .item{
//       position: relative;
//       width: 100%;
//         h2{
//           font-size: 45px; 
//           width: 100%;
//           ;line-height: 55px;
//           color: $white-color;
//           font-family: 'Jura', sans-serif;
//           font-weight: 400;
//           padding: 0 30px;
//           margin-bottom: 0;
//           @include breakpoint-down(xs) {
//             font-size: 40px;
//             line-height: 50px;
//             margin-bottom: 0;
//           }
//         }
     
//       p{
//         font-size: 22px;
//         line-height: 28px;
//         color: $white-color;
//         font-weight: 300;
//         padding: 21px 30px 30px 30px;
//         @include breakpoint-down(xs) {
//           font-size: 18px;
//           line-height: 25px;
//         }
//       }
//     }
//     .owl-nav{
//       width: 100%;
//       position: absolute;
//       bottom: 100px;
//       @include breakpoint-down(xs) {
//         bottom: 8px;
//         text-align: center;
//       }
//       .owl-prev{
//         position: absolute;
//         left: -90px;
//         background: url('../../Assets/Images/bannerArrowPrev.png') no-repeat;
//         width: 65px;
//         height: 65px;
//         float: left;
//         font-size: 0;
//         transition: transform .2s;
//         @include breakpoint-down(sm) {
//           width: 50px;
//           height: 50px;
//           background-size: 50px;
//         }
//         @include breakpoint-down(xs) {
//           position: relative;
//           left: 0;
//           float: none;
//           margin: 0 5px;
//           background-size: 35px;
//           width: 35px;
//           height: 35px;
//         }
//         &:hover{
//           transform: scale(1.1);
//         }
//       }
//       .owl-next{
//         position: absolute;
//         right: -90px;
//         background: url('../../Assets/Images/bannerArrowNext.png') no-repeat;
//         width: 65px;
//         height: 65px;
//         float: right;
//         font-size: 0;
//         transition: transform .2s;
//         @include breakpoint-down(sm) {
//           width: 50px;
//           height: 50px;
//           background-size: 50px;
//         }
//         @include breakpoint-down(xs) {
//           position: relative;
//           right: 0;
//           float: none;
//           margin: 0 5px;
//           background-size: 35px;
//           width: 35px;
//           height: 35px;
//         }
//         &:hover{
//           transform: scale(1.1);
//         }
//       }
//     }
//     .owl-dots{
//       position: absolute;
//       top: 0;
//       background-color: $base-primary;
//       width: 100%;
//       display:flex;
//       align-items: center;
//       justify-content: flex-start;
//       .owl-dot{
//         width: 50px;
//         height: 5px;
//         background-color: $base-primary;
//         &.active{
//           background-color: $white-color;
//         }
//         &:last-child{
//           margin: 0;
//         }
//         span{
//           display: none;
//         }
//       }
//     }
//   }
// }

.bannerWrapperNew{
  width: 100%;
  background:#e1e1e1 url('../../Assets/Images/banner.jpg') no-repeat;
  height: calc(100vh - 0px);
  background-size: cover;
  background-position: top center;
  position: relative;
  .owl-carousel{
    position: relative;
    .owl-stage-outer{
      .owl-stage{
        .owl-item{
          height: 100vh;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .itemCover{
              width: 70%;
              text-align: center;
            .item{
                width: 100%;
              h2{
                color: $white-color;
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 1rem;
                font-family: 'Barlow', sans-serif;
                font-weight: 500;
                letter-spacing: 1.5px;
              }
              p{
                color: $white-color;
                width: 80%;
                margin: 0 auto;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
    .owl-nav{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      width: 100%;
      padding: 0 2rem;
      .owl-prev{
        width: 58px;
        height: 58px;
        font-size: 0;
        background-color: transparent;
        border: 1px solid $white-color;
        border-radius: 50%;
        transition: all 0.6s ease;
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10px;
          background: url('../../Assets/Images/banner-white-arrow.png') no-repeat;
          background-size: 21px;
          transform: rotate(180deg);
          background-position: center center;
        }
        &:hover{
          transform: scale(1);
          transition: all 0.4s ease;
          width: 64px;
          height: 64px;
          background-color: $white-color;
        }
        &:hover span{
          background: url('../../Assets/Images/arrow_icon_hover.png') no-repeat;
          transition: all 0.5s ease;
          background-size: 21px;
          transform: rotate(180deg);
          background-position: center center;
        }
      }
      .owl-next{
        width: 58px;
        height: 58px;
        font-size: 0;
        background-color: transparent;
        border: 1px solid $white-color;
        border-radius: 50%;
        transition: all 0.6s ease;
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10px;
          background: url('../../Assets/Images/banner-white-arrow.png') no-repeat;
          background-size: 21px;
          transform: rotate(0deg);
          background-position: center center;
        }
        &:hover{
          transform: scale(1);
          transition: all 0.4s ease;
          width: 64px;
          height: 64px;
          background-color: $white-color;
        }
        &:hover span{
          background: url('../../Assets/Images/arrow_icon_hover.png') no-repeat;
          transition: all 0.5s ease;
          background-size: 21px;
          transform: rotate(0deg);
          background-position: center center;
        }
      }
    }
  }
}
